<template>
  <div class="social-main">
    <div class="arena-embed-widget" data-publisher="tribespot" data-type="social-wall" data-showtitle="true" data-lang="sq" data-timeline="yp5d"></div>
  </div>
</template>
<script>
export default {
  name: "ArenaSocial",
  computed: {
    settings() {
      return this.$store.state.Config.setting;
    },
    chatActive() {
      return this.$state.Livestreams.livestream.chat.type !== "";
    },
    currentUser() { 
      return this.$store.getters["Auth/currentUser"]
    }
  },
}
</script>
<style lang="scss">
.social-main {
  flex-grow: 1;
}
</style>
